import { Component, OnInit } from "@angular/core";
import { DemoService } from "src/app/services/demo.service";

@Component({
  selector: "app-kym-strategic-site-plan-page",
  templateUrl: "./kym-strategic-site-plan-page.component.html",
  styleUrls: ["./kym-strategic-site-plan-page.component.scss"],
})
export class KymStrategicSitePlanPageComponent implements OnInit {
  public strategicSitePlan = "";
  public brandGoals = "BRAND GOAL 1";
  public challengesOpportunities = {
    challenges: [
      {
        index: 0,
        value: "",
      },
      {
        index: 1,
        value: "",
      },
      {
        index: 2,
        value: "",
      },
    ],
    opportunities: [
      {
        index: 0,
        value: "",
      },
      {
        index: 1,
        value: "",
      },
      {
        index: 2,
        value: "",
      },
    ],
  };
  public objectives = [
    {
      index: 0,
      objective:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at tempor libero. Nullam et ipsum ac tortor tincidunt efficitur vel vitae elit. ",
      strategy:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at tempor libero. Nullam et ipsum ac tortor tincidunt efficitur vel vitae elit. ",
      actions: [
        {
          item: "",
          timing: "",
          owner: "",
        },
        {
          item: "",
          timing: "",
          owner: "",
        },
        {
          item: "",
          timing: "",
          owner: "",
        },
      ],
      expanded: false,
    },
  ];

  constructor(public demo: DemoService) {}

  ngOnInit() {
    if (localStorage.getItem("kym-acc-strategic-site-plan") !== null) {
      let snapshot = JSON.parse(
        localStorage.getItem("kym-acc-strategic-site-plan")
      );
      console.log(snapshot);

      // challenges & opportunities
      this.challengesOpportunities = snapshot.challengesOpportunities;

      // strategic site map & brand goals
      this.strategicSitePlan = snapshot.inputs.site_plan;
      this.brandGoals = snapshot.inputs.brand_goals;

      // objectives
      this.objectives = snapshot.objectives;
    }

    if (this.objectives.length == 1) {
      this.objectives[0].expanded = true;
    }
  }

  expandCollapseObjectives(index) {
    if (
      this.objectives.filter((item) => item.index == index)[0].expanded == true
    ) {
      this.objectives.forEach((item) => {
        item.expanded = false;
      });
    } else {
      this.objectives.forEach((item) => {
        if (item.index == index) {
          item.expanded = true;
        } else {
          item.expanded = false;
        }
      });
    }
  }

  demoUpdateElement(parent, event, index) {
    let target = event.originalTarget || event.srcElement;
    parent.filter((item) => item.index == index)[0].value = target.value;
    this.demoCreateSnapshot();
  }

  demoAddNewElement(id) {
    let indices = this.challengesOpportunities[id].map((item) => item.index);
    this.challengesOpportunities[id].push({
      index: Math.max(...indices) + 1,
      value: "",
    });
    this.demoCreateSnapshot();
  }

  demoRemoveElement(parent, id, index) {
    this.challengesOpportunities[id] = parent.filter(
      (item) => item.index !== index
    );
    this.demoCreateSnapshot();
  }

  demoCreateSnapshot() {
    let sanitizedObjectives = JSON.parse(JSON.stringify(this.objectives));
    sanitizedObjectives.forEach((item) => {
      item.expanded = false;
    });

    let structured = {
      inputs: {
        site_plan: this.strategicSitePlan,
        brand_goals: this.brandGoals,
      },
      challengesOpportunities: this.challengesOpportunities,
      objectives: sanitizedObjectives,
    };

    localStorage.setItem(
      "kym-acc-strategic-site-plan",
      JSON.stringify(structured)
    );
  }

  objectiveTitleShortcut(full_text) {
    if (full_text.length > 50) {
      return (
        ": " + full_text.substring(0, Math.min(full_text.length, 50)) + "..."
      );
    } else if (full_text.trim() !== "") {
      return ": " + full_text;
    } else {
      return "";
    }
  }

  demoRemoveObjective(index) {
    if (this.objectives.length > 1) {
      if (confirm("Do you want to remove this objective?")) {
        this.objectives = this.objectives.filter(
          (item) => item.index !== index
        );
        this.demoCreateSnapshot();
      }
    } else {
      alert("Strategic site plan requires at least one objective.\r\nThis objective cannot be removed.");
    }
  }

  demoAddNewObjective() {
    let indices = this.objectives.map((item) => item.index);
    console.log(indices);
    this.objectives.push({
      index: Math.max(...indices) + 1,
      objective: "",
      strategy: "",
      actions: [
        {
          item: "",
          timing: "",
          owner: "",
        },
        {
          item: "",
          timing: "",
          owner: "",
        },
        {
          item: "",
          timing: "",
          owner: "",
        },
      ],
      expanded: false,
    });
    this.demoCreateSnapshot();
  }
}
