<div id="fixed-title">
  <div id="fixed-title-location">
    <div><b>ACCOUNT DASHBOARD</b></div>
    <div
      class="responsive-button"
      style="background-image: url(assets/reset.svg); right: 90px;"
      (click)="this.demo.reset()"
    ></div>
    <div
      class="responsive-button"
      style="background-image: url(assets/logout.svg);"
      routerLink="/login"
    ></div>
  </div>
  <div id="fixed-title-welcome">
    <div>
      <div>Good morning <b>Pete Alonso</b>!</div>
    </div>
    <div id="fixed-title-welcome-region">
      <div>EAST REGION</div>
    </div>
  </div>
</div>
<div id="container" class="no-menu">
  <h2>Account Dashboard</h2>
  <p>Select an account to review or update.</p>
  <div id="accounts-box">
    <div routerLink="/account">
      <div>NYU Langone</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Dana-Farber Cancer Institute</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Montefiore Medical Center</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Robert Wood Johnson University Hospital</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Hackensack University Medical Center</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Mass General</div>
    </div>
    <div (click)="this.demo.notAvailable()">
      <div>Memorial Sloan-Kettering Cancer Center</div>
    </div>
    <div class="add-new" (click)="this.showAccountCreation = true">
      <div><img src="/assets/add.svg" /></div>
    </div>
  </div>
</div>
<div id="new-account-creation-container" *ngIf="this.showAccountCreation">
  <div>
    <div>
      <h5>Add new account</h5>
      <div>
        <div>ACCOUNT NAME</div>
        <div><input type="text" /></div>
      </div>
      <div>
        <div>PRIORITY</div>
        <div>
          <select>
            <option>Priority 1</option>
            <option>Priority 2</option>
            <option>Priority 3</option>
          </select>
        </div>
      </div>
      <div>
        <div>ADDRESS</div>
        <div><input type="text" /></div>
        <div><input type="text" /></div>
      </div>
      <div style="text-align: right; margin-top: 40px; margin-bottom: 0px;">
        <div>
          <button (click)="this.showAccountCreation = false">CANCEL</button>
          <button (click)="this.demo.notAvailable()">+ ADD NEW ACCOUNT</button>
        </div>
      </div>
    </div>
  </div>
</div>
