<div id="login-main-container">
  <div id="login-box">
    <img id="login-logo" src="/assets/kymriah.png" alt="" />
    <div>
      <div>LOGIN</div>
      <input type="text" [(ngModel)]="this.username" placeholder="enter your login" pattern="[A-Za-z0-9]{1,20}" />
    </div>
    <div>
      <div>PASSWORD</div>
      <input type="password" [(ngModel)]="this.password" placeholder="enter your password" pattern="[A-Za-z0-9]{1,20}" />
    </div>
    <div style="text-align: center;">
      <button [className]="(this.username == null || this.password == null) ? 'disabled' : ''" (click)="this.demoCheckCredentials()" id="login-continue">CONTINUE</button>
    </div>
  </div>
</div>
