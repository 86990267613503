<div id="fixed-title">
  <div id="fixed-title-location">
    <div><b>NYU LANGONE</b> ACCOUNT PROFILE</div>
    <div
      class="responsive-button"
      style="background-image: url(assets/home.svg);"
      routerLink="/dashboard"
    ></div>
  </div>
  <div id="fixed-title-welcome">
    <div>
      <div>Good morning <b>Pete Alonso</b>!</div>
    </div>
    <div id="fixed-title-welcome-region">
      <div>EAST REGION</div>
    </div>
  </div>
</div>
<div id="fixed-menu-container">
  <div id="fixed-menu">
    <div></div>
    <div routerLink="/account">
      Account Dashboard
    </div>
    <div class="active">
      Account Profile
    </div>
    <div (click)="this.demo.notAvailable()">
      Account Map
    </div>
    <div (click)="this.demo.notAvailable()">
      Referral Network
    </div>
    <div (click)="this.demo.notAvailable()">
      Key Account Contacts
    </div>
    <div routerLink="/strategic-plan">
      Strategic Site Plan
    </div>
    <div (click)="this.demo.notAvailable()">
      Key Stakeholders
    </div>
    <div (click)="this.demo.notAvailable()">
      Scorecard
    </div>
    <div (click)="this.demo.notAvailable()">
      Order Process
    </div>
    <div></div>
  </div>
</div>
<div id="container">
  <h2><b>NYU Langone</b> Account profile</h2>
  <div id="account-core">
    <div>
      <div>
        <div>ACCOUNT PROFILE (SITE NAME)</div>
        <div style="margin-bottom: 15px;">
          <input
            type="text"
            (keyup)="this.createDemoSnapshot()"
            value="NYU Langone"
          />
        </div>
      </div>
      <div>
        <div>PRIORITY LEVEL</div>
        <div>
          <select (change)="createDemoSnapshot()">
            <option>Priority 1</option>
            <option>Priority 2</option>
            <option>Priority 3</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="into-columns">
    <div class="column">
      <div class="account-generic">
        <div class="collapsible">
          <div>
            <div>Certification status</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
          <div>
            <div>EMR System</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
        </div>
        <div class="collapsible">
          <div>
            <div>Type</div>
            <div>
              <div class="toggle">
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />PEDIATRIC
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />ADULT
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Yescarta available</div>
            <div>
              <div class="toggle">
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="collapsible">
          <div>
            <div>Account Specialty Distributor</div>
            <div>
              <div class="checkboxes">
                <div class="active" (click)="this.demoCheckbox($event)">
                  <img src="assets/checkbox_checked.svg" class="checked" /><img
                    src="assets/checkbox_unchecked.svg"
                    class="unchecked"
                  />CARDINAL
                </div>
                <div (click)="this.demoCheckbox($event)">
                  <img src="assets/checkbox_checked.svg" class="checked" /><img
                    src="assets/checkbox_unchecked.svg"
                    class="unchecked"
                  />CURASCRIPT
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Order Flow Documented</div>
            <div>
              <div class="toggle">
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="account-generic box">
        <div class="collapsible altLayout">
          <div>
            <div>KYMRIAH Lead Coordinator</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
          <div>
            <div>Phone/e-mail</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
        </div>
        <div class="collapsible altLayout">
          <div>
            <div>Authorized Representative Name</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
          <div>
            <div>Phone/e-mail</div>
            <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
          </div>
        </div>
        <div>
          <div>
            Affiliation with other KYMRIAH Site (example, pediatric hospital)
          </div>
          <div><input (keyup)="this.createDemoSnapshot()" type="text" /></div>
        </div>
      </div>
    </div>
  </div>
  <h3>
    Standing department meetings<button (click)="this.demo.notAvailable()">
      + ADD NEW
    </button>
  </h3>
  <div id="department-meetings-container">
    <div class="department-meeting">
      <div class="core" (click)="this.expandCollapseDepartmentMeeting($event)">
        <div><img src="/assets/event2.svg" /></div>
        <div>Standing department meeting<span>4 attendees</span></div>
        <div>5/15/2020, 3:42 AM<span>Location</span></div>
      </div>
      <div class="details">
        <div></div>
        <div>
          <h4>Attendees:</h4>
          <ul>
            <li>Attendee 1</li>
            <li>Attendee 2</li>
            <li>Attendee 3</li>
            <li>Attendee 4</li>
          </ul>
          <button (click)="this.demo.notAvailable()">EDIT DETAILS</button>
        </div>
      </div>
    </div>
    <div class="department-meeting">
      <div class="core" (click)="this.expandCollapseDepartmentMeeting($event)">
        <div><img src="/assets/event2.svg" /></div>
        <div>Standing department meeting<span>4 attendees</span></div>
        <div>5/15/2020, 3:42 AM<span>Location</span></div>
      </div>
      <div class="details">
        <div></div>
        <div>
          <h4>Attendees:</h4>
          <ul>
            <li>Attendee 1</li>
            <li>Attendee 2</li>
            <li>Attendee 3</li>
            <li>Attendee 4</li>
          </ul>
          <button (click)="this.demo.notAvailable()">EDIT DETAILS</button>
        </div>
      </div>
    </div>
    <div class="department-meeting">
      <div class="core" (click)="this.expandCollapseDepartmentMeeting($event)">
        <div><img src="/assets/event2.svg" /></div>
        <div>Standing department meeting<span>4 attendees</span></div>
        <div>5/15/2020, 3:42 AM<span>Location</span></div>
      </div>
      <div class="details">
        <div></div>
        <div>
          <h4>Attendees:</h4>
          <ul>
            <li>Attendee 1</li>
            <li>Attendee 2</li>
            <li>Attendee 3</li>
            <li>Attendee 4</li>
          </ul>
          <button (click)="this.demo.notAvailable()">EDIT DETAILS</button>
        </div>
      </div>
    </div>
  </div>
  <div class="into-columns">
    <div class="column">
      <h3>Current Position of KYMRIAH in ALL Treatment Algorithm</h3>
      <div class="account-generic">
        <div class="collapsible">
          <div>
            <div>On Formulary</div>
            <div>
              <div class="toggle">
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Included in EMR</div>
            <div>
              <div class="toggle">
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="collapsible">
          <div>
            <div>
              Established Pathways/Protocols/Order Sets Include Kymriah?
            </div>
            <div>
              <div class="toggle">
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Outpatient Use of KYMRIAH:</div>
            <div>
              <div class="toggle" (click)="demoSwitchToggle($event)">
                <div>
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />BOTH
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <h3>Current Position of KYMRIAH in DLBCL Treatment Algorithm</h3>
      <div class="account-generic">
        <div class="collapsible">
          <div>
            <div>On Formulary</div>
            <div>
              <div class="toggle">
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Included in EMR</div>
            <div>
              <div class="toggle">
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="collapsible">
          <div>
            <div>
              Established Pathways/Protocols/Order Sets Include Kymriah?
            </div>
            <div>
              <div class="toggle">
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Outpatient Use of KYMRIAH:</div>
            <div>
              <div class="toggle">
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />YES
                </div>
                <div class="active" (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />NO
                </div>
                <div (click)="demoSwitchToggle($event)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />BOTH
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
