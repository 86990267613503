import { Component, OnInit } from "@angular/core";
import { DemoService } from "src/app/services/demo.service";

@Component({
  selector: "app-kym-account-profile-page",
  templateUrl: "./kym-account-profile-page.component.html",
  styleUrls: ["./kym-account-profile-page.component.scss"],
})
export class KymAccountProfilePageComponent implements OnInit {
  constructor(public demo: DemoService) {}

  ngOnInit() {
    if(localStorage.getItem("kym-acc-account-profile") !== null) {
      this.restoreDemoSnapshot();
    }
  }

  expandCollapseDepartmentMeeting(event) {
    let target = event.originalTarget || event.srcElement;
    if (target.parentNode.parentNode.classList.contains("expanded")) {
      target.parentNode.parentNode.classList.remove("expanded");
    } else {
      let departmentMeetings = document.getElementsByClassName(
        "department-meeting"
      );
      for (let i = 0; i < departmentMeetings.length; i++) {
        departmentMeetings[i].classList.remove("expanded");
      }
      target.parentNode.parentNode.classList.add("expanded");
    }
  }

  demoSwitchToggle(event) {
    let target = event.originalTarget || event.srcElement;
    target.parentNode.querySelector(".active").classList.remove("active");
    target.classList.add("active");
    this.createDemoSnapshot();
  }

  demoCheckbox(event) {
    let target = event.originalTarget || event.srcElement;
    if (target.classList.contains("active")) {
      target.classList.remove("active");
    } else {
      target.classList.add("active");
    }
    this.createDemoSnapshot();
  }

  restoreDemoSnapshot() {
    let snapshot = JSON.parse(localStorage.getItem("kym-acc-account-profile"));

    // toggles
    let toggles = document.querySelectorAll(".toggle");
    snapshot.toggles.forEach((item) => {
      toggles[item.index].querySelector(".active").classList.remove("active");
      toggles[item.index].children[item.selected].classList.add("active");
    })

    // inputs
    let inputs = document.querySelectorAll("input");
    snapshot.inputs.forEach((item) => {
      inputs[item.index].value = item.value;
    })

    // checkboxes
    let checkboxes = document.querySelectorAll(".checkboxes");
    snapshot.checkboxes.forEach((item) => {
      for(let i = 0; i < checkboxes[item.index].children.length; i++) {
        checkboxes[item.index].children[i].classList.remove("active");
      }
      item.checked.forEach((checked) => {
        checkboxes[item.index].children[checked].classList.add("active");
      })
    })

    // selects
    let selects = document.querySelectorAll("select");
    snapshot.selects.forEach((item) => {
      selects[item.index].value = item.value;
    })


  }

  createDemoSnapshot() {
    let structured = {
      toggles: [],
      checkboxes: [],
      inputs: [],
      selects: [],
    };

    // toggles
    let toggles = document.querySelectorAll(".toggle");
    for (let i = 0; i < toggles.length; i++) {
      let activeIndex = -1;
      for (let x = 0; x < toggles[i].children.length; x++) {
        if (toggles[i].children[x].classList.contains("active")) {
          activeIndex = x;
          break;
        }
      }
      structured.toggles.push({
        index: i,
        selected: activeIndex,
      });
    }

    // inputs
    let inputs = document.querySelectorAll("input");
    for(let i = 0; i < inputs.length; i++) {
      structured.inputs.push({
        index: i,
        value: inputs[i].value
      })
    }

    // checkboxes
    let checkboxes = document.querySelectorAll(".checkboxes");
    for(let i = 0; i < checkboxes.length; i++) {
      let checked = [];
      for(let x = 0; x < checkboxes[i].children.length; x++) {
        if(checkboxes[i].children[x].classList.contains("active")) {
          checked.push(x);
        }
      }
      structured.checkboxes.push({
        index: i,
        checked: checked
      })
    }

    // selects
    let selects = document.querySelectorAll("select");
    for(let i = 0; i < selects.length; i++) {
      structured.selects.push({
        index: i,
        value: selects[i].value
      })
    }

    localStorage.setItem("kym-acc-account-profile", JSON.stringify(structured));
  }
}
