import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AccountDashboardPageComponent } from './components/account-dashboard-page/account-dashboard-page.component';
import { AccountDetailsPageComponent } from './components/account-details-page/account-details-page.component';
import { RequiredActionsPageComponent } from './components/required-actions-page/required-actions-page.component';
import { KymAccountProfilePageComponent } from './components/kym-account-profile-page/kym-account-profile-page.component';
import { KymStrategicSitePlanPageComponent } from './components/kym-strategic-site-plan-page/kym-strategic-site-plan-page.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'dashboard',
    component: AccountDashboardPageComponent
  },
  {
    path: 'account',
    component: AccountDetailsPageComponent
  },
  {
    path: 'required-actions',
    component: RequiredActionsPageComponent
  },
  {
    path: 'required-actions',
    component: RequiredActionsPageComponent
  },
  {
    path: 'account-profile',
    component: KymAccountProfilePageComponent
  },
  {
    path: 'strategic-plan',
    component: KymStrategicSitePlanPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
