import { Component, OnInit } from "@angular/core";
import { DemoService } from "src/app/services/demo.service";

@Component({
  selector: "app-account-details-page",
  templateUrl: "./account-details-page.component.html",
  styleUrls: ["./account-details-page.component.scss"],
})
export class AccountDetailsPageComponent implements OnInit {
  constructor(public demo: DemoService) {}

  public addingNewMember: boolean = false;
  public selectingMember;
  public team = [
    {
      name: "Pete Alonso",
      position: "1st Baseman",
      email: "Pete.Alonso@NYMets.com",
      phone: "862-778-1986",
      picture: "/assets/users/petealonso.png",
      display: true,
    },
    {
      name: "Noah Syndegaard",
      position: "Pitcher",
      email: "Thor@NYMets.com",
      phone: "862-778-1986",
      picture: "/assets/users/noahsyndegaard.png",
      display: true,
    },
    {
      name: "Michael Conforto",
      position: "Right Field",
      email: "Mike.Conforto@NYMets.com",
      phone: "862-778-1986",
      picture: "/assets/users/michaelconforto.png",
      display: false,
    },
  ];

  public requiredActions = [
    {
      index: 0,
      title: "Connect with Dr. Zhnang (new HCP)",
      owner: "Pete Alonso",
      due: "May 15th 2020",
      target: "Dr. Zhang, Pediatric Oncology, Pds.zhang@Langone.com",
      action: "Introductions and Kymriah overview",
      completed: false,
      expanded: false,
    },
    {
      index: 1,
      title: "Connect with NP Morrison (Process training)",
      owner: "Michael Conforto",
      due: "May 19th 2020",
      target:
        "NP Julia Morrison, Oncology Patient Care, Jd.morrison@Langone.com",
      action: "Requested training fornursing staff",
      completed: true,
      expanded: false,
    },
    {
      index: 2,
      title: "Connect with Dr. Richards (Kymriah Info needed)",
      owner: "Pete Alonso",
      due: "May 22th 2020",
      target: "Dr. Richards, Oncologist, Df.Richards@langone.com",
      action: "Requested information on Kymriah",
      completed: false,
      expanded: false,
    },
  ];

  ngOnInit() {
    if (localStorage.getItem("kym-acc-account-members") !== null) {
      this.team = JSON.parse(localStorage.getItem("kym-acc-account-members"));
    }
    if(localStorage.getItem("kym-acc-required-actions") !== null) {
      this.requiredActions = JSON.parse(localStorage.getItem("kym-acc-required-actions"));
      this.requiredActions.forEach((item) => {
        item.expanded = false;
      })
    }
  }

  expandCollapseAccountMember(event) {
    let target = event.originalTarget || event.srcElement;
    if (target.parentNode.parentNode.classList.contains("expanded")) {
      target.parentNode.parentNode.classList.remove("expanded");
    } else {
      let accountMembers = document.getElementsByClassName("account-member");
      for (let i = 0; i < accountMembers.length; i++) {
        accountMembers[i].classList.remove("expanded");
      }
      target.parentNode.parentNode.classList.add("expanded");
    }
  }

  initializeMemberCreation() {
    this.addingNewMember = true;
    //this.selectingMember = null;
    this.selectingMember = this.team.filter(
      (item) => item.display == false
    )[0].name;
  }

  demoRemoveAccountMember(name) {
    if (this.team.filter((item) => item.display == true).length > 1) {
      if (confirm("Do you want to remove this member from the account team?")) {
        this.team.filter((item) => item.name == name)[0].display = false;
        this.saveAccountMembersSnapshot();
      }
    } else {
      alert("Team has to have at least one member.");
    }
  }

  areThereMembersToAdd(): boolean {
    return this.team.filter((item) => item.display == false).length > 0;
  }

  demoAddNewMember() {
    let item = this.team.filter((item) => item.name == this.selectingMember)[0];
    item.display = true;
    this.team.push(this.team.splice(this.team.indexOf(item), 1)[0]);
    this.addingNewMember = false;
    this.saveAccountMembersSnapshot();
  }

  saveAccountMembersSnapshot() {
    localStorage.setItem("kym-acc-account-members", JSON.stringify(this.team));
  }

  demoSwitchToggle(event, status, index) {
    let target = event.originalTarget || event.srcElement;
    target.parentNode.querySelector(".active").classList.remove("active");
    target.classList.add("active");

    this.requiredActions.filter((item) => item.index == index)[0].completed = status;

    this.demoSaveRequiredActions();
  }

  demoSaveRequiredActions() {
    console.log(this.requiredActions);
    localStorage.setItem(
      "kym-acc-required-actions",
      JSON.stringify(this.requiredActions)
    );
  }

  demoExpandRequiredActions(index) {
    if (
      this.requiredActions.filter((item) => item.index == index)[0].expanded ==
      true
    ) {
      this.requiredActions.forEach((item) => {
        item.expanded = false;
      });
    } else {
      this.requiredActions.forEach((item) => {
        if (item.index !== index) {
          item.expanded = false;
        } else {
          item.expanded = true;
        }
      });
    }
  }
}
