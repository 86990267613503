<div id="fixed-title">
  <div id="fixed-title-location">
    <div><b>NYU LANGONE</b> ACCOUNT DASHBOARD</div>
    <div
      class="responsive-button"
      style="background-image: url(assets/home.svg);"
      routerLink="/dashboard"
    ></div>
  </div>
  <div id="fixed-title-welcome">
    <div>
      <div>Good morning <b>Pete Alonso</b>!</div>
    </div>
    <div id="fixed-title-welcome-region">
      <div>EAST REGION</div>
    </div>
  </div>
</div>
<div id="fixed-menu-container">
  <div id="fixed-menu">
    <div></div>
    <div class="active">
      Account Dashboard
    </div>
    <div routerLink="/account-profile">
      Account Profile
    </div>
    <div (click)="this.demo.notAvailable()">
      Account Map
    </div>
    <div (click)="this.demo.notAvailable()">
      Referral Network
    </div>
    <div (click)="this.demo.notAvailable()">
      Key Account Contacts
    </div>
    <div routerLink="/strategic-plan">
      Strategic Site Plan
    </div>
    <div (click)="this.demo.notAvailable()">
      Key Stakeholders
    </div>
    <div (click)="this.demo.notAvailable()">
      Scorecard
    </div>
    <div (click)="this.demo.notAvailable()">
      Order Process
    </div>
    <div></div>
  </div>
</div>
<div id="container">
  <h2><b>NYU Langone</b> Account Dashboard</h2>
  <h3>
    Account team
    <button
      *ngIf="this.areThereMembersToAdd()"
      (click)="this.initializeMemberCreation()"
    >
      + ADD NEW
    </button>
  </h3>
  <div id="account-team">
    <div *ngFor="let member of this.team" class="account-member">
      <ng-container *ngIf="member.display">
        <div class="core" (click)="expandCollapseAccountMember($event)">
          <div><img class="photo" src="{{ member.picture }}" /></div>
          <div>
            {{ member.name }}<span>{{ member.position }}</span>
          </div>
          <div>
            <img class="info help" src="/assets/help.svg" /><img
              class="info collapse"
              src="/assets/collapse.svg"
            />
          </div>
        </div>
        <div class="details">
          <div></div>
          <div>
            <ul>
              <li>{{ member.email }}</li>
              <li>{{ member.phone }}</li>
              <li>
                <button (click)="demoRemoveAccountMember(member.name)">
                  REMOVE FROM THIS ACCOUNT TEAM
                </button>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div id="recent-activity-container">
    <h3>
      Recent activity <button (click)="this.demo.notAvailable()">MORE</button>
    </h3>
    <div id="recent-activity">
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Account Profile</div>
        <div>5/15/2020, 3:42 AM<span>P. Alonso</span></div>
      </div>
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Account Map</div>
        <div>5/15/2020, 3:21 AM<span>P. Alonso</span></div>
      </div>
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Strategic Site Plan</div>
        <div>4/28/2020, 10:04 AM<span>M. Conforto</span></div>
      </div>
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Account Map</div>
        <div>4/26/2020, 5:15 PM<span>N. Syndegaard</span></div>
      </div>
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Referral Network</div>
        <div>4/13/2020, 9:27 AM<span>P. Alonso</span></div>
      </div>
      <div>
        <div><img src="/assets/event.svg" /></div>
        <div>Updated Order Process</div>
        <div>3/31/2020, 2:05 AM<span>M. Conforto</span></div>
      </div>
    </div>
  </div>
  <div id="required-actions-container">
    <h3>
      Required actions
      <button (click)="this.demo.notAvailable()">+ ADD NEW</button>
    </h3>
    <div id="required-actions">
      <div class="required-action" [class.expanded]="action.expanded" *ngFor="let action of this.requiredActions">
        <div class="core" (click)="this.demoExpandRequiredActions(action.index)">
          <div><img src="/assets/warning.svg" /></div>
          <div>{{action.title}}</div>
          <div>{{action.due}}<span>{{action.owner}}</span></div>
        </div>
        <div class="details">
          <div></div>
          <div>
            <div class="required-action-fields">
              <div>
                <div>TARGET</div>
                <div><textarea [(ngModel)]="action.target" (keyup)="this.demoSaveRequiredActions()"></textarea></div>
              </div>
              <div>
                <div>ACTION</div>
                <div><textarea [(ngModel)]="action.action" (keyup)="this.demoSaveRequiredActions()"></textarea></div>
              </div>
            </div>
            <div class="required-action-fields">
              <div>
                <div>OWNER</div>
                <div>
                  <select [(ngModel)]="action.owner" (change)="this.demoSaveRequiredActions()">
                    <option *ngFor="let member of this.team">{{member.name}}</option>
                  </select>
                </div>
              </div>
              <div>
                <div>COMPLETE BY</div>
                <div><input [(ngModel)]="action.due" type="text" (keyup)="this.demoSaveRequiredActions()" disabled /></div>
              </div>
            </div>
            <div class="for-completion">
              <span>COMPLETION STATUS</span>
              <div class="toggle">
                <div [class.active]="action.completed" (click)="demoSwitchToggle($event, true, action.index)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />COMPLETED
                </div>
                <div [class.active]="!action.completed" (click)="demoSwitchToggle($event, false, action.index)">
                  <img src="assets/radio_checked.svg" class="checked" /><img
                    src="assets/radio_unchecked.svg"
                    class="unchecked"
                  />INCOMPLETE
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="new-member-creation-container" *ngIf="this.addingNewMember">
  <div>
    <div>
      <h5>ADD NEW MEMBER</h5>
      <div>
        <div>SELECT NEW TEAM MEMBER</div>
        <div>
          <select [(ngModel)]="this.selectingMember">
            <ng-container *ngFor="let member of this.team">
              <option *ngIf="member.display == false">{{ member.name }}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div style="text-align: right; margin-top: 40px; margin-bottom: 0px;">
        <div>
          <button (click)="this.addingNewMember = false">CANCEL</button>
          <button
            (click)="this.demoAddNewMember()"
            [className]="this.selectingMember == null ? 'disabled' : ''"
          >
            + ADD NEW MEMBER
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
