import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AccountDashboardPageComponent } from './components/account-dashboard-page/account-dashboard-page.component';
import { AccountDetailsPageComponent } from './components/account-details-page/account-details-page.component';
import { RequiredActionsPageComponent } from './components/required-actions-page/required-actions-page.component';
import { KymAccountProfilePageComponent } from './components/kym-account-profile-page/kym-account-profile-page.component';
import { KymStrategicSitePlanPageComponent } from './components/kym-strategic-site-plan-page/kym-strategic-site-plan-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AccountDashboardPageComponent,
    AccountDetailsPageComponent,
    RequiredActionsPageComponent,
    KymAccountProfilePageComponent,
    KymStrategicSitePlanPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
