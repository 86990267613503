import { Component, OnInit } from '@angular/core';
import { DemoService } from 'src/app/services/demo.service';

@Component({
  selector: 'app-account-dashboard-page',
  templateUrl: './account-dashboard-page.component.html',
  styleUrls: ['./account-dashboard-page.component.scss']
})
export class AccountDashboardPageComponent implements OnInit {

  public showAccountCreation : boolean = false;

  constructor(
    public demo : DemoService
  ) { }

  ngOnInit() {
  }

}
